import {defineComponent, getCurrentInstance, nextTick, onMounted, ref, watch} from 'vue';
import {SearchUsersObject, User, UserGroup, UserRole} from '../../../target/api/de/moovit/titletoolserver/model';
import {UserApi, UserGroupApi} from '../../../target/api';

export default defineComponent({
  props: ["user", "isVisible", "isApprover"],
  components: {},
  setup: function (props, context) {
    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const userApi: UserApi = new UserApi();
    const userGroupApi: UserGroupApi = new UserGroupApi();

    let isActive = ref<boolean>(false);
    let isWindowTooSmall = ref<boolean>(false);

    let currentUsername = ref<string>('');
    let currentEmail = ref<string>('');

    let currentSurname = ref<string>('');
    let currentFirstname = ref<string>('');

    let currentPassword = ref<string>('');
    let currentConfirmPassword = ref<string>('');

    let groups = ref<Array<UserGroup>>([]);
    let currentGroups = ref<Array<UserGroup>>([]);
    let currentCostCenter = ref<string>('');
    let currentRole = ref<UserRole>();
    let currentCountry = ref<{ name: string, value: string }>({name: '', value: ''});
    let currentDefaultLanguage = ref<{ name: string, value: string }>({name: '', value: ''});
    let locked = ref(false);

    let submitted = ref<boolean>(false);
    let valid = ref<boolean>();
    let errorMessage = ref<string>('');

    let existingUsers = ref<User[]>();
    let existingName = ref<boolean>(false);

    let defaultPassword = ref<string>(":!M0=v!T§$=%)/Pa44w0r6!.");

    let toggleDialog = (visible: boolean) => {
      if (visible) {
        isActive.value = true;
        valid.value = false;
        submitted.value = false;

        currentUsername.value = "";
        currentFirstname.value = "";
        currentEmail.value = "";
        currentCostCenter.value = "";
        currentSurname.value = "";
        currentPassword.value = "";
        currentConfirmPassword.value = "";
        currentDefaultLanguage.value = {name: "", value: ""};
        currentCountry.value = {name: "", value: ""};
        currentGroups.value = [];
        currentRole.value = undefined;

        setTimeout(() => {
          if (props.user) {
            _loadEditUser(props.user);
          }
        }, 200);
      } else {
        isActive.value = false;
      }
      context.emit("update:isVisible", isActive.value);
    }

    let _loadEditUser = (user: User) => {
      currentUsername.value = user.id!;
      currentFirstname.value = user.firstname!;
      currentEmail.value = user.email!;
      currentCostCenter.value = user.costCenter!;
      currentSurname.value = user.name!;

      currentPassword.value = defaultPassword.value;
      currentConfirmPassword.value = defaultPassword.value;

      if (user.defaultLanguage) {
        currentDefaultLanguage.value = {name: user.defaultLanguage, value: user.defaultLanguage};
      }
      if (user.country) {
        currentCountry.value = {name: user.country, value: user.country};
      }
      if (user.groups && user.groups.length > 0) {
        let userGroups: UserGroup[] = [];
        for (let group of user.groups) {
          let fullGroup = groups.value.find(value => value.id === group.id);
          if (fullGroup) {
            userGroups.push(fullGroup);
          }
        }

        currentGroups.value = userGroups;
      }
      if (user.role) {
        currentRole.value = user.role!;
      }
    }

    let verifyUsername = () => {
      if (!currentUsername.value) {
        existingName.value = false;
        return;
      }
      userApi.getUserById(currentUsername.value).then(result => {
        if (result.data) {
          errorMessage.value = "Username already existing.";
          existingName.value = true;
        } else {
          existingName.value = false;
        }
      }).finally(() => {
        if (currentUsername.value!.length >= 30) {
          errorMessage.value = "Username too long.";
          existingName.value = true;
        }
      });
    }

    let editUser = () => {
      submitted.value = true;

      if (
        !currentUsername.value ||
        !currentEmail.value ||
        !currentSurname.value ||
        !currentFirstname.value ||
        !currentPassword.value ||
        !currentConfirmPassword.value ||
        currentGroups.value.length === 0 ||
        !currentRole.value ||
        (currentCountry.value.name === "")
      ) {
        return;
      }

      if (currentPassword.value != currentConfirmPassword.value) {
        currentConfirmPassword.value = '';
        return;
      }

      updateUser();
    };

    let updateUser = async () => {
      nextTick(async () => {
        let user: User = {};

        user.id = currentUsername.value;
        user.name = currentSurname.value;
        user.firstname = currentFirstname.value;
        user.email = currentEmail.value;

        if (currentPassword.value !== defaultPassword.value) {
          user.password = currentPassword.value;
        }

        if (currentCostCenter.value) {
          user.costCenter = currentCostCenter.value;
        }

        user.country = JSON.parse(JSON.stringify(currentCountry.value)).value;

        user.groups = currentGroups.value;

        user.role = currentRole.value;
        user.locked = locked.value;

        await userApi.updateUser(user);
      }).then(() => {
        setTimeout(() => {
          emitter.emit('search-users');
          toggleDialog(false);
          valid.value = true;
        }, 50);
      });
    };

    let addNewUser = async () => {
      submitted.value = true;

      if (existingName.value) {
        valid.value = false;
        return;
      }

      if (currentPassword.value != currentConfirmPassword.value) {
        currentConfirmPassword.value = '';
        return;
      }

      if (
        !currentUsername.value ||
        !currentEmail.value ||
        !currentSurname.value ||
        !currentFirstname.value ||
        !currentPassword.value ||
        !currentConfirmPassword.value ||
        currentGroups.value.length === 0 ||
        !currentRole.value ||
        (currentCountry.value.name === "")
      ) {
        return;
      }

      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(currentEmail.value!)) {
        currentEmail.value = '';
        return;
      }

      valid.value = true;

      let user: User = {};

      user.id = currentUsername.value;
      user.name = currentSurname.value;
      user.firstname = currentFirstname.value;
      user.email = currentEmail.value;
      user.password = currentPassword.value;
      user.costCenter = currentCostCenter.value;
      user.defaultLanguage = currentDefaultLanguage.value ? currentDefaultLanguage.value.value : undefined;

      user.country = JSON.parse(JSON.stringify(currentCountry.value)).value;
      user.groups = currentGroups.value;
      user.role = currentRole.value;
      user.locked = locked.value;

      console.debug("user to be added: ", user);

      nextTick(async () => {
        await userApi.addUser(user);
      }).then(() => {
        emitter.emit('search-users', null);
      })
    }

    let onClickDone = async () => {
      if (props.user) {
        editUser();
      } else {
        addNewUser();
      }
    }

    let _resize = () => {
      if (document.documentElement.clientWidth < 1000) {
        if (!isWindowTooSmall.value) {
          isWindowTooSmall.value = true;
        }
      } else {
        if (isWindowTooSmall.value) {
          isWindowTooSmall.value = false;
        }
      }
    }

    watch(props, () => {
      if (props.isVisible !== undefined) {
        toggleDialog(props.isVisible);
      }
    });

    watch(isActive, () => {
      toggleDialog(isActive.value);
    });

    onMounted(async () => {
      let searchUsersObject: SearchUsersObject = {};
      searchUsersObject.page = 0;
      searchUsersObject.pageSize = 1000;
      existingUsers.value = (await userApi.searchUsers(searchUsersObject)).data;

      userGroupApi.searchUserGroups().then(response => {
        if (response.data) {
          groups.value = response.data;
        }
      });

      window.addEventListener('resize', function () {
        _resize();
      });
    });

    return {
      toggleDialog,
      onClickDone,
      groups,
      currentGroups,
      currentRole,
      currentCountry,
      currentDefaultLanguage,
      currentCostCenter,
      locked,
      currentUsername,
      currentEmail,
      currentSurname,
      currentFirstname,
      currentPassword,
      currentConfirmPassword,

      isWindowTooSmall,
      isActive,

      verifyUsername,
      existingName,

      submitted,
      errorMessage,
      valid,
    };
  },
});
