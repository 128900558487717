<template>
  <RoundedLabel v-if="visible" :text="'Add Custom VoiceOver'" class="bg-dark-blue col-white"
                @click="toggleDialog()"/>
  
  <pDialog :header="'Audio Recording'" v-model:visible="isActive"
           style="width: 95%; height: 95% !important;" :draggable="false" :maximizable="false"
           :style="isRecording ? 'border: 2px solid red' : ' border: 2px solid transparent'"
           :modal="true">
    
    <div style="display: flex; flex-direction: column; gap: 10px">
      <div style="display: flex; gap: 15px; width: 100%">
        <div style="width: 65%; gap: 15px">
          <div style="display: flex; flex-direction: column; gap: 15px">
            <div class="border-radius-16"
                 style="display: flex; flex-direction: column; margin-bottom: 5px; align-items: flex-end; justify-content: flex-end; background-color: #000000">
              <div
                class="video-container"
                style="display: flex; flex-direction: column; align-items: center; width: 100%"
              >
                <video
                  id="audioTrackVideo"
                  ref="playerVideoControls"
                  class="video-js vjs-default-skin"
                  preload="metadata"
                  @ended="onVideoEnded()"
                  style="
                    width: 100%;
                    height: inherit;
                    overflow: hidden;
                    background: transparent;
                    pointer-events: none;
                  "
                />
              </div>
              <div
                style="display: flex; justify-content: space-between; align-items: center; padding: 0 10px 0 10px; width: 100%; height: 35px; border-bottom-left-radius: 12px; border-bottom-right-radius: 12px; background-color: var(--background)">
                <div style="width: 60px">
                  <RoundedLabel
                    :leftIcon="playButtonIcon"
                    class="border-radius-8 col-dark-blue"
                    :noHover="true"
                    style="
                      width: 25px;
                      padding-left: 8px;
                      height: 25px;
                      border: none;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                    @click="onClickPlayButton()"
                  />
                </div>
                <div style=" width: 80%; margin-left: 5px; margin-right: 5px">
                  <Timeline
                    :timecode="playerSliderPosition"
                    :assetItemId="item.id + '000'"
                    :videoLength="currentTime"
                  />
                </div>
                <div style="width: 60px">
                  {{getCurrentTimecode(currentTime)}}
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%; min-height: 70px">
            <FieldVisualizer
              :show-timeline="true"
              :video-position="currentTime"
              :video-length="playerDuration"
              :field-ids="audioFields && audioFields.length > 0 ? audioFields.map(field => field.id) : []"
            />
          </div>
        </div>
        
        <div style="display: flex; flex-direction: column; gap: 20px; width: 35%; height: 60%">
          <div style="display: flex; gap: 15px">
            <div>
              <Button v-if="!isRecording" class="border-radius-24 bg-white bor-dark-blue"
                      style="height: 50px; width: 50px"
                      @click="startRecording()">
                <i class="pi pi-circle-on" style="margin-top: 1px; font-size: 18px; color: red"/>
              </Button>
              
              <Button v-else class="border-radius-24 bg-white bor-dark-blue"
                      style="display: flex; height: 50px; width: 50px; align-items: center; justify-content: center"
                      @click="stopRecording()">
                <div style="height: 15px; width: 15px; background-color: black"></div>
              </Button>
            </div>
            
            <CustomDropdown v-model:model-value="microphone"
                            :options="microphones"
                            placeholder="Microphones"
                            label="Microphones"
                            optionLabel="label"
                            style="width: 288px"/>
          </div>
          
          <div>
            Recording:
            {{microphones.length === 0 ? "NOT READY" : isRecording ? "RECORDING" : "READY"}}
          </div>
          
          <div style="display: flex; flex-direction: column; gap: 15px; width: 100%; height: 600px; overflow-x: hidden; overflow-y: auto">
            <div v-for="(audio, index) in audioFields" class="bor-dark-blue bg-menu-color-2 border-radius-12"
                 style="display: flex; justify-content: space-between; align-items: center; width: 100%; height: 70px; padding: 15px">
              <div>
                <div style="font-size: 20px">
                  {{audio.value}}
                </div>
                <div style="font-size: 14px" class="col-dark-blue-30">
                  {{audio.inPoint}}s -
                  {{audio.outPoint}}s
                </div>
              </div>
              <div>
                <i class="pi pi-trash hover-icon"
                   style="margin: 5px; padding: 5px" @click="onClickField(index)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </pDialog>
</template>

<style>
.p-dialog-content {
  height: 100%;
}

</style>

<script src="./VoiceOverRecorder.ts"></script>
