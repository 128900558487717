import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";

import {
  Asset,
  AssetStatus,
  AssetType,
  SearchAssetsObject,
  User,
  UserRole,
} from "../../../target/api/de/moovit/titletoolserver/model";
import { AssetApi, UserApi } from "../../../target/api";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import main from "@/main";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  setup: function() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const i18n: any = useI18n();

    const app: any = getCurrentInstance();
    const emitter: any = app.appContext.config.globalProperties.$emitter;

    const assetApi: AssetApi = new AssetApi();
    const userApi = new UserApi();

    const editJobConfirmationToast = useToast();

    let jobs = ref([]);
    let jobsDataTable = ref<Element | null>();
    let sortColumn = ref("created");
    let sortAsc = ref(false);
    let searchText = ref("");
    let page: number = 5;
    let pageSize: number = 20;
    let parentId = ref<string>("");
    let addingAssets = ref(false);
    let added0Assets = ref(false);

    let lastClickedItem = ref<string>();

    let jobIdNewlyCreated = ref<string[]>([]);

    let selectedColumns = ref<string[]>([]);
    let allColumns = ref<string[]>([
      "general.expiration",
      "general.id",
      "general.language",
      "general.group",
      "general.translator",
    ]);

    let getAssets: any = async () => {
      let searchAssetsObject: SearchAssetsObject = {};
      searchAssetsObject.page = page;
      searchAssetsObject.pageSize = pageSize;
      searchAssetsObject.searchText = searchText.value;
      searchAssetsObject.assetType = AssetType.TRANSLATION;
      searchAssetsObject.ascSort = sortAsc.value;
      searchAssetsObject.sortColumn = sortColumn.value;
      searchAssetsObject.parentID = parentId.value;

      searchAssetsObject.groups = [];
      searchAssetsObject.languages = [];
      searchAssetsObject.translators = [];
      searchAssetsObject.assetStatus = [];

      let user: User = (
        await userApi.getUserById(JSON.parse(localStorage.loginData).user.id)
      ).data;
      let userIsAdmin: boolean = user.role == UserRole.ADMIN;

      for (let i in store.state.filterBreadcrumbs) {
        let filterBreadcrumb = store.state.filterBreadcrumbs[i];
        if (filterBreadcrumb.type == "GROUP")
          searchAssetsObject.groups.push(filterBreadcrumb.name);
        if (filterBreadcrumb.type == "TRANSLATOR")
          searchAssetsObject.translators.push(filterBreadcrumb.name);
        if (filterBreadcrumb.type == "LANGUAGE")
          searchAssetsObject.languages.push(filterBreadcrumb.name);
        if (filterBreadcrumb.type == "STATUS")
          searchAssetsObject.assetStatus.push(filterBreadcrumb.name);
        if (filterBreadcrumb.type == "ID")
          searchAssetsObject.thirdPartyID = filterBreadcrumb.name;
        if (filterBreadcrumb.type == "CUSTOM")
          searchAssetsObject.customFilter = filterBreadcrumb.name;
        if (filterBreadcrumb.type == "COSTCENTER")
          searchAssetsObject.costCenter = filterBreadcrumb.name;
      }

      if (!userIsAdmin && user && user.groups) {
        for (const group of user.groups) {
          if (group.id) {
            searchAssetsObject.groups.push(group.id);
          }
        }
      }

      console.debug("searchAssetsObject", searchAssetsObject);
      let searchAssetsResponse = await assetApi.searchAssets(
        searchAssetsObject
      );

      if (searchAssetsResponse) {
        console.debug("JobsView - ITEMS", searchAssetsResponse.data);
        return searchAssetsResponse.data;
      }
    };

    let searchAssets = async () => {
      page = 0;
      jobs.value = [];
      jobs.value = await getAssets();
      added0Assets.value = false;
    };

    let addAssets = async () => {
      if (addingAssets.value || added0Assets.value) return;

      addingAssets.value = true;
      page++;

      let newJobs: any = await getAssets();
      jobs.value = jobs.value.concat(newJobs);
      console.debug("ADDED " + newJobs.length + " ASSETS");

      if (newJobs.length == 0) {
        added0Assets.value = true;
      }

      addingAssets.value = false;
    };

    let onBlurSearchField = () => {
      console.debug("onBlurSearchField = ()");
      searchAssets();
    };

    let changeSort = (column: string) => {
      if (sortColumn.value == column) {
        sortAsc.value = !sortAsc.value;
      } else {
        sortColumn.value = column;
        sortAsc.value = true;
      }
      searchAssets();
    };

    let handleScroll = (event: any) => {
      let scrollMax = event.target.scrollHeight - event.target.clientHeight;
      let scrollPos = event.target.scrollTop;
      if (scrollMax - scrollPos < 150 && !addingAssets.value) {
        addAssets();
      }
    };

    let downloadJob = (item: Asset, flag: string) => {
      console.debug("Selected Itemname : " + item.name);
      console.debug("Selected Country  : " + flag);
    };

    let getMappedAssetStatus = (assetStatus: string) => {
      if (assetStatus == "NEW") return i18n.t("assetStatus.new");
      if (assetStatus == "COMPLETE") return i18n.t("assetStatus.complete");
      if (assetStatus == "INPROGRESS") return i18n.t("assetStatus.inProgress");
      if (assetStatus == "DELETED") return i18n.t("assetStatus.deleted");
      if (assetStatus == "READYTOAPPROVE")
        return i18n.t("assetStatus.readyToApprove");
    };

    let toggleSubscription = (asset: Asset) => {
      let userId = JSON.parse(localStorage.loginData).user.id;

      if (!asset.subscribers) {
        asset.subscribers = [];
      }

      if (!asset.subscribers.includes(userId)) {
        asset.subscribers.push(userId);
      } else {
        asset.subscribers = asset.subscribers.filter(
          (obj: any) => obj !== userId
        );
      }

      assetApi.updateAsset({ asset: asset, updateJobs: false });
    };

    let isSubscript = (asset: Asset) => {
      let userId = JSON.parse(localStorage.loginData).user.id;

      return asset.subscribers && asset.subscribers.includes(userId);
    };

    let hasPermission = (permString: string) => {
      return JSON.parse(localStorage.loginData).user.permissions.permissions[
        permString
      ];
    };

    let deleteJob = async (item: Asset) => {
      if (item && item.id) {
        localStorage.savedAsset = JSON.stringify(item);
        await assetApi.deleteAsset(item.id);
        main.config.globalProperties.$emitter.emit("search-jobs", null);
      }
    };

    let undoDeleteJob = async () => {
      await assetApi.addAsset(JSON.parse(localStorage.savedAsset));
      main.config.globalProperties.$emitter.emit("search-jobs", null);
      store.commit("HIDE_SNACKBAR");
    };

    let resetJob = (job: Asset) => {
      if (job) {
        console.debug("resetJob");
        console.debug(job.tokenObject!.expiration);
        let newDate = new Date();
        newDate.setDate(newDate.getDate() + 15);
        console.debug(newDate.toISOString().split("T")[0]);
        job.tokenObject!.expiration = newDate.toISOString();

        assetApi.updateAsset({ asset: job, updateJobs: true });
      }
    };

    let isExpired = (job: Asset) => {
      let tok: any = job.tokenObject;
      let jobDate: any;
      if (tok) jobDate = new Date(tok.expiration);

      return (
        getDaysToNow(jobDate) < 0 &&
        (job.assetStatus == AssetStatus.NEW ||
          job.assetStatus == AssetStatus.INPROGRESS)
      );
    };

    let setLastClickedItem = (event: any) => {
      lastClickedItem.value = event.data.id;
    };

    let onClick = (e: any) => {
      if (e.target.nodeName !== "SPAN") {
        return;
      }

      let asset: Asset = jobs.value.filter((value: Asset) => {
        return value.id === lastClickedItem.value;
      })[0];

      if (!canEdit(asset)) {
        router.push({
          name: "job",
          query: { id: asset.id },
        });

        if (asset.assetStatus == AssetStatus.NEW) {
          asset.assetStatus = AssetStatus.INPROGRESS;
          assetApi.updateAsset({ asset: asset, updateJobs: false });
        }
      }
    };

    let getDaysToNow = (date: string) => {
      let oneDay: number = 24 * 60 * 60 * 1000;
      let now: any = new Date(Date.now());
      now.setDate(now.getDate() - 1);
      let jobDate: any = new Date(date);
      let diffDays = Math.floor((jobDate - now) / oneDay);
      return diffDays;
    };

    let getExpirationText = (date: string) => {
      let diffDays = getDaysToNow(date);

      if (diffDays > 30) return new Date(date).toDateString();
      if (diffDays == 1) return i18n.t("general.inXdays", 1);
      if (diffDays > 0) return i18n.t("general.inXdays", [diffDays], 2);
      if (diffDays == 0) return i18n.t("general.inXdays", 0);

      return new Date(date).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    };

    let canEdit = (asset: Asset) => {
      if (
        asset != null &&
        hasPermission("jobs_view/click_items/completed") &&
        (asset.assetStatus === "COMPLETE" ||
          asset.assetStatus === "READYTOAPPROVE")
      ) {
        return false;
      }

      if (
        asset != null &&
        hasPermission("jobs_view/click_items/in_progress") &&
        (asset.assetStatus === "NEW" || asset.assetStatus === "INPROGRESS")
      ) {
        return false;
      }

      return true;
    };

    let onDataTableSort = (event: any) => {
      let sortField = event.multiSortMeta[0];
      if (sortField && sortField.field == "language") {
        sortColumn.value = "language";
        sortAsc.value = sortField.order == 1;
        searchAssets();
      } else if (
        event.multiSortMeta.length == 0 &&
        sortColumn.value == "language"
      ) {
        sortColumn.value = "";
        sortAsc.value = false;
        searchAssets();
      }
    };

    watch(selectedColumns, () => {
      localStorage.setItem(
        "selectedJobColumns",
        JSON.stringify(selectedColumns.value)
      );
    });

    onMounted(() => {
      if (route.query && route.query.new) {
        jobIdNewlyCreated.value = <string[]>route.query.new;
      }

      emitter.on("search-jobs", () => {
        searchAssets();
      });

      emitter.on("download-job", (items: any) => {
        downloadJob(items[0], items[1]);
      });

      document.addEventListener("click", onClick);

      if (router.currentRoute.value.query.assetId) {
        parentId.value = router.currentRoute.value.query.assetId.toString();
      }

      let parentJobsHolder = document.getElementById("jobsDataTable");

      if (parentJobsHolder) {
        jobsDataTable.value = parentJobsHolder.querySelector(":first-child");
        if (jobsDataTable.value) {
          jobsDataTable.value.addEventListener("scroll", handleScroll);
        }
      }

      if (localStorage.getItem("selectedJobColumns")) {
        selectedColumns.value = JSON.parse(
          localStorage.getItem("selectedJobColumns")!
        );
      } else {
        selectedColumns.value = allColumns.value;
      }

      searchAssets();

      let tJobs = {
        t: "activityBreadcrumbs.jobs",
        e: undefined,
      };
      store.commit("SET_ACTIVITY_BREADCRUMBS", [tJobs]);
    });

    onUnmounted(() => {
      emitter.off("search-jobs");
      emitter.off("download-job");
      document.removeEventListener("click", onClick);

      if (jobsDataTable.value) {
        jobsDataTable.value.removeEventListener("scroll", handleScroll);
      }
    });

    return {
      jobs,
      jobsDataTable,
      sortColumn,
      sortAsc,
      searchText,
      jobIdNewlyCreated,
      changeSort,
      searchItems: searchAssets,
      onBlurSearchField,
      setLastClickedItem,

      getMappedAssetStatus,
      toggleSubscription,
      isSubscript,
      hasPermission,
      undoDeleteJob,
      deleteJob,
      resetJob,
      isExpired,
      getExpirationText,
      onClick,
      onDataTableSort,

      handleScroll,

      selectedColumns,
      allColumns,

      addingAssets,
      added0Assets,
    };
  },
});
